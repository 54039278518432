// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,  
   
    //Staging
     //API_Base_URL: 'https://telemedicinepvtapi.esdinfra.com/api/',
     //Image_Url: 'https://telemedicinepvtapi.esdinfra.com/'
     
  // Medgrama
    API_Base_URL: 'https://telemedphcapi.medgramaone.com/api/',
    Image_Url: 'https://telemedphcapi.medgramaone.com/'



  // API_Base_URL:'http://localhost:32973/api/',
  // Image_Url:'http://localhost:32973/'

  // API_Base_URL: 'https://demo.pvtapi.esdinfra.com/api/',
  // Image_Url: 'https://demo.pvtapi.esdinfra.com/'

     //live
  // API_Base_URL: 'https://telmed.medetel.in/api/',
  // Image_Url:'https://telmed.medetel.in/' 
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
